<template>
  <div>
    <div class="container-fluid py-3">
      <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
               :is-full-page="true"></loading>
      <div v-if="recipe.Method" class="recipe-details">
        <div class="img-block">
          <div class="favorite-i">
            <a v-if="recipe.is_fav"><i class="fas fa-heart"></i></a>
            <a class="ntFvIcon" v-else @click="favoriteThis(recipe.id)">
              <i class="far fa-heart"></i>
            </a>
          </div>
          <img v-lazy="recipe.image_url"  alt=""/>
          <div v-if="recipe.vid && recipe.vid !== 'null'" class="video-icon" @click="getVideoId(recipe.vid)">
            <i class="far fa-play-circle"></i>
          </div>
        </div>
        <h3 class="name">{{recipe.RecipieName}}</h3>
        <star-rating :star-size="25" :border-width="3" border-color="#ce3770" inactive-color="#fff"
                     active-color="#ce3770" :show-rating="false" :rating="parseFloat(avgRating)" :read-only="true"
                     :increment=".5">
        </star-rating>
        <div class="content">
          <div class="column details">
            <h2>Ingredients</h2>
            <div class="ingredients-block">
              <a v-for="(ing, index) in recipe.Ingredients" :key="index"
                 @click="postIngredienttoCart(ing)"
                 :class="[ingredientInCart(ing) ? 'cart-ingredient' : 'ingredient']">{{ing}}<i
                  class="fas fa-plus"></i></a>
            </div>
            <div class="htc-block">
              <h2>How to Cook</h2>
              <span v-if="recipe.time_estimate"><i class="far fa-clock"></i>
                                {{recipe.time_estimate}} {{recipe.time_unit || ' mins'}}</span>
            </div>
            <div class="methods-block">
              <div class="method-list">
                <p v-for="(method,index) in recipe.Method" :key="index">{{checkMethodFormat(index,method)}}</p>
              </div>
            </div>
          </div>
          <div class="column reviews">
            <div class="top-header">
              <h2>Reviews</h2>
              <span @click="addReviewModal" class="addReviewIcon"><i
                  class="fas fa-plus-square"></i></span>
            </div>
            <base-alert v-if="noReviews" type="default">
                            <span class="alert-inner--text"><strong>No reviews!</strong> Add your review by clicking on
                                Add Review button.</span>
            </base-alert>
            <div class="review" v-for="review in recipe.reviews" :key="review.id">
              <h4 class="review-text">{{review.reviewer}}</h4>
              <star-rating :star-size="18" :border-width="2" border-color="#ce3770" inactive-color="#fff"
                           active-color="#ce3770" :show-rating="false" :rating="parseFloat(review.rating)"
                           :increment=".5" :read-only="true">
              </star-rating>
              <h3 class="review-text">{{review.review}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video PopUp -->
    <slide-y-up-transition mode="out-in" origin="center top">
      <div id="recVid" v-if="showVideo" class="video-modal">
        <div class="body">
          <span class="closeBtn" @click="showVideo=false"><i class="fa fa-times"></i></span>
          <youtube @ended="ended" :player-vars="{autoplay: 1}" :video-id="videoId"></youtube>
        </div>
      </div>
    </slide-y-up-transition>
    <!-- Video PopUp Ends -->

    <!-- Add Reviews PopUp -->
    <slide-y-up-transition mode="out-in" origin="center top">
      <div id="recReview" v-if="reviewModal" class="review-modal">
        <div class="body">
          <h2>Add Review</h2>
          <span class="closeBtn" @click="reviewModal=false"><i class="fa fa-times"></i></span>
          <star-rating :star-size="24" :border-width="2" border-color="#ce3770" inactive-color="#fff"
                       active-color="#ce3770" :show-rating="false" :increment="1" @rating-selected="setRecipeRating"
                       v-model="newReview.rating">
          </star-rating>
          <div class="form-group">
            <label>Review:</label>
            <input type="text" v-model="newReview.review" v-validate="'required:min:3'" name="review"
                   class="form-control" />
            <span v-show="errors.has('review')" class="help is-danger">{{ errors.first('review') }}</span>
          </div>
          <div class="form-group">
            <label>Private:</label>
            <select class="form-control" v-model="newReview.private" v-validate="'required'" name="private">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
          <div class="form-group text-right">
            <button class="btn" @click="addRecipeRating(recipe.id)" type="button">Submit</button>
          </div>
        </div>
      </div>
    </slide-y-up-transition>
    <!-- Add Reviews PopUp Ends -->
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import 'vue-loading-overlay/dist/vue-loading.css';
import StarRating from 'vue-star-rating';
import {SlideYUpTransition} from 'vue2-transitions'
import {getIdFromURL} from "vue-youtube-embed";
// import {getIdFromURL} from "vue-youtube-embed";

export default {
  name: "RecipesDetails",
  components: {
    StarRating,
    Loading,
    SlideYUpTransition
  },
  data: () => {
    return {
      videoId: '',
      cartIngredients: null,
      recipe: {},
      ingredients: {},
      noReviews: false,
      avgRating: '',
      newReview: {
        private: 0,
        rating: 1
      },
      showVideo: false,
      reviewModal: false,
      isLoading: false,
      existingReview: null,
      isFavorited: '',
      config: {
        headers: {
          Accept: 'application/json'
        }
      },
    }
  },
  methods: {
    getVideoId: function (url){
      this.showVideo=true
      this.videoId = getIdFromURL(url)
    },
    getCartIngredients: function () {
      this.isLoading = true;
      var data = {
        device_id: localStorage.getItem('device_id')
      }
      axios.post('/api/cartIngredients', data, this.config)
          .then(response => {
            console.log(response.data.success)
            this.cartIngredients = response.data.ingredients;
          }, error => {
            console.log(error);
            return error;
          })
      this.getSingleRecipe();
    },
    getSingleRecipe: function () {
      var data = {
        recipe_id: this.$route.params.recipe_id,
        device_id: localStorage.getItem('device_id')
      };
      axios.post('/api/get-web-recipe', data, this.config)
          .then(response => {
            this.recipe = response.data.recipie;
            this.avgRating = response.data.avg;
            this.isLoading = false;
            if (this.recipe.reviews.length == 0) {
              this.noReviews = true
            }
          }, error => {
            console.log(error);
            this.$swal(
                "Not Found",
                'Unknown Entry.',
                "error"
            );
            this.$router.push({
              path: "/"
            });
          });
    },
    favoriteThis: function ($id) {
      var data = {
        recipie_id: $id,
        device_id: localStorage.getItem('device_id')
      };
      this.isLoading = true;
      axios.post('/api/favrecipie', data, this.config)
          .then(response => {
            console.log(response);
            this.isLoading = false;
            this.$toast.open({
              message: "Recipe added to your favorites.",
              type: 'success',
            });
            this.recipe.is_fav = true;
          }, error => {
            console.log(error);
            this.$toast.open({
              message: "Something went wrong.",
              type: 'error',
            });
          });
    },
    postIngredienttoCart: function (ingredient) {
      this.$toast.clear();
      if (this.cartIngredients && this.cartIngredients.some(data => data.ingredient.toLowerCase() ==
          ingredient.toLowerCase())) {
        this.removeIngredientFromCart(ingredient);
      } else {
        var api_data = {
          ingredients: ingredient,
          device_id: localStorage.getItem('device_id')
        };
        this.isLoading = true;
        axios.post('/api/cart', api_data, this.config)
            .then(response => {
              console.log(response);
              this.$toast.open({
                message: "Ingredient added to the cart.",
                type: 'success',
              });
              this.isLoading = false;
              this.getCartIngredients();
            }, error => {
              console.log(error);
              this.$toast.open({
                message: "Something went wrong.",
                type: 'error',
              });
            })
      }
    },
    removeIngredientFromCart: function (ingredient) {
      var cart_data = {
        ingredient: ingredient,
        device_id: localStorage.getItem('device_id')
      };
      this.isLoading = true;
      axios.post('/api/change', cart_data, this.config)
          .then(response => {
            this.isLoading = false;
            this.$toast.open({
              message: "Ingredient removed from cart.",
              type: 'success',
            });
            this.getCartIngredients();
            return response;
          }, error => {
            console.log(error);
            this.$toast.open({
              message: "Something went wrong.",
              type: 'error',
            });
          })
    },
    addReviewModal: function () {
      if (this.getParent('site-layout').loggedIn === false) {
        this.$toast.open({
          message: "Please login/register to add review.",
          type: 'info',
        });
        this.getParent('site-layout').userModal = true;
        this.getParent('site-layout').loggedIn = false;
      } else {
        this.reviewModal = true;
      }

    },
    addRecipeRating: function ($id) {
      this.$validator.validate().then((result) => {
        if (result) {
          var data = {
            recipie_id: $id,
            device_id: localStorage.getItem('device_id'),
            reviewer: localStorage.getItem('name'),
            rating: this.newReview.rating,
            review: this.newReview.review,
            private: this.newReview.private,
          };
          this.isLoading = true;
          axios.post('/api/addreview', data, this.config)
              .then(response => {
                console.log(response);
                this.isLoading = false;
                this.newReview = {
                  private: 0,
                  rating: 1
                }
                this.getSingleRecipe();
                this.reviewModal = false;
                this.$toast.open({
                  message: "Review submitted Successfully.",
                  type: 'success',
                });
              }, error => {
                console.log(error);
                this.$toast.open({
                  message: "Something went wrong.",
                  type: 'error',
                });
              });
        }
      }).catch(() => {});
    },
    setRecipeRating: function (rating) {
      this.newReview.rating = rating;
      console.log(this.newReview.rating);
    },
    getParent(name) {
      let p = this.$parent;
      while (typeof p !== 'undefined') {
        if (p.$options.name == name) {
          return p;
        } else {
          p = p.$parent;
        }
      }
      return false;
    },
    ingredientInCart: function (name) {
      if (this.cartIngredients && this.cartIngredients.some(data => data.ingredient.toLowerCase() == name
          .toLowerCase())) {
        return true
      } else {
        return false
      }
    },
    checkMethodFormat: function(index, method){
      if(method.match(/^(?:[0-9]{1,2}:?)(\s-\s)|^(?:[0-9]{1,2}:?)(-)|^(?:[0-9]{1,2}:?)(-\s)|^(?:[0-9]{1,2}:?)(\s-)/)){
        return method;
      }else{
        return (index + 1) + '-' + method;
      }
    },
    ended () { this.showVideo = false }
  },
  mounted() {
    this.getCartIngredients();
  }
}
</script>

<style scoped>
.img-block {
  position: relative;
  margin-bottom: 20px;
}

.img-block img {
  width: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.video-icon {
  right: 50px;
  position: absolute;
  bottom: -30px;
  color: #ffffff;
  border-radius: 50%;
  font-size: 2rem;
  background: white;
  box-shadow: 0px 0px 7px 1px #000000;
  cursor: pointer;
}

.video-icon i {
  padding: 14px;
  color: rgba(0, 0, 0, .7);
}

.recipe-details .content {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}

.recipe-details .content .column.details {
  width: 58%;
}

.recipe-details .content .column.reviews {
  width: 38%;
  max-height: 700px;
  overflow-y: auto;
  padding: 20px;
}

.recipe-details .content .reviews h2 {
  margin: 0;
  line-height: normal;
}

.recipe-details .content .reviews .top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.recipe-details .content .reviews .top-header .addReviewIcon {
  cursor: pointer;
}

.recipe-details .content .reviews .top-header .addReviewIcon i {
  color: #ce3770;
  font-size: 26px;
}

.recipe-details .content .column.reviews .review .vue-star-rating {
  margin-bottom: 10px;
}

.recipe-details .content .column.reviews .review {
  padding: 10px;
  background: rgba(255, 255, 255, .9);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 3px solid #ce3770;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .4);
}

.recipe-details .content .column.reviews .review h3 {
  color: #444;
  font-size: 16px;
  margin-bottom: 0px;
}

.recipe-details .content .name {
  font-size: 32px;
  color: #ce3770;
  margin-bottom: 10px;
}

.recipe-details .content .vue-star-rating {
  margin-bottom: 20px;
}

.view-reviews {
  border-bottom: 2px solid #ce3770;
  padding-bottom: 3px;
}

.ingredient,
.cart-ingredient {
  display: inline-block;
  background: rgba(0, 0, 0, .7);
  color: #fff !important;
  padding: 5px 10px;
  border-left: 5px solid #ce3770;
  margin-bottom: 10px;
  cursor: pointer;
}

.cart-ingredient {
  background-color: #ce3770;
  border-left: 5px solid #152c17;
  margin-right: 10px;
}

.ingredient:not(:last-child) {
  margin-right: 10px;
}

.ingredient i {
  color: #ce3770 !important;
  margin-left: 10px;
}

.cart-ingredient i {
  display: none;
}

.favorite-i {
  right: 30px;
  position: absolute;
  font-size: 2rem;
  color: #ce3770;
  top: 10px;
}

.share-btn {
  font-size: 1.5rem;
  color: #ce3770;
}

h2 {
  font-size: 2rem;
  color: #ce3770;
  margin: 10px 0;
}

.methods-block p {
  font-size: 16px;
  font-weight: 500;
  color: #444;
  word-break: break-word;
  margin-bottom: 10px;
}

.video-modal,
.review-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-top: 100px;
  background-color: rgba(0, 0, 0, .5);
}

.video-modal .body,
.review-modal .body {
  width: 60%;
  border-radius: 10px;
  position: relative;
}

.review-modal .body {
  background-color: #fff;
  padding: 20px;
}

.review-modal .body h2 {
  text-align: center;
  margin: 0;
}

.review-modal .body .form-group {
  margin-bottom: 10px;
}

.review-modal .body label {
  margin-bottom: 2px;
}

.review-modal .body .vue-star-rating {
  margin-bottom: 20px;
}

.review-modal .body .btn {
  background-color: #ce3770;
  color: #fff;
  width: 200px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  margin-left: auto;
}

.closeBtn {
  position: absolute;
  right: -5px;
  top: -10px;
  border-radius: 50%;
  z-index: 999999;
  background-color: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closeBtn i {
  color: #000;
  font-size: 20px;
}

.ntFvIcon {
  cursor: pointer;
}

.alert-default {
  background-color: #ce3770;
  border: 0;
}

.htc-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.htc-block h2 {
  margin: 0;
}

.htc-block span {
  color: #ce3770;
}
</style>
