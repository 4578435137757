<template>
    <div class="container text-center">
        <div class="wrapper-mylist my-3">
            <router-link class="nav-link d-inline ingredients-link" to="/ingredients-list-category">Ingredients List
            </router-link>
            <router-link class="nav-link d-inline shopping-link" to="/shopping-list">Shopping List</router-link>
        </div>
    </div>
</template>
<script>
    export default {}
</script>
<style>
    .wrapper-mylist {
        display: flex !important;
        justify-content: center;
    }

    .wrapper-mylist .nav-link {
        display: flex !important;
        width: 180px;
        height: 45px;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: 1px solid grey;
    }

    .wrapper-mylist .ingredients-link {
        border-right: 0;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }

    .wrapper-mylist .shopping-link {
        border-left: 0;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .router-link-active {
        background: #ce3770;
        border-color: #ce3770 !important;
        color: #ffffff !important;
    }
</style>