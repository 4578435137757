<template>
    <div class="container-fluid text-center">
        <div class="wrapper-mylist my-4">
            <router-link class="nav-link d-inline favourites-link" to="/favorite-recipes" >Favourites</router-link>
            <router-link class="nav-link d-inline created-recipes-link" to="/created-recipes" >Created Recipes</router-link>
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style>
.wrapper-mylist{
    display: flex;
    justify-content: center;
}
.wrapper-mylist .nav-link{
    display: flex !important;
    width: 180px;
    height: 45px;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 1px solid grey;
}
.wrapper-mylist .favourites-link{
    border-right: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}
.wrapper-mylist .created-recipes-link{
    border-left: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}
.router-link-active{
    background: #ce3770;
    border-color: #ce3770 !important;
    color:#ffffff!important;
}

@media (max-width: 575.98px) { 
.favourites-link, .created-recipes-link{
  display:block!important;
  border-radius: 50px!important;
}
.wrapper-mylist {
    border: none!important;
    padding: 0px !important;
    border-radius: 50px;
}
 }
</style>