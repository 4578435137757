<template>
    <div class="container-fluid">
        <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
            :is-full-page="true"></loading>
        <div class="explore-recipes">
            <div v-for="recipe in trending" :key="recipe.id" class="recipe">
                <router-link :to="{ name: 'RecipesDetails', params: { recipe_id: recipe.id }}">
                    <img v-lazy="recipe.image_url" alt="" class="banner-img">
                    <h3 class="name">{{recipe.RecipieName}}</h3>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                trending: [],
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                },
            }
        },
        methods: {
            getExploreRecipes: function () {
                this.loading = true;
                console.log(this.$route.params.id);
                axios.get('/api/explore-recipes', this.config)
                    .then(response => {
                        this.loading = false;
                        this.trending = response.data.trending;
                    }, error => {
                        console.log(error);
                    });
            }
        },
        mounted: function () {
            this.getExploreRecipes();
        }
    };
</script>
<style>
    .explore-recipes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 30px 0;
        padding: 0 50px;
    }

    .explore-recipes .recipe {
        width: 48%;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .2);
        margin-bottom: 30px;
        border-radius: 10px;
        overflow: hidden;
    }

    .explore-recipes .recipe img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .explore-recipes .recipe .name {
        color: #ce3770;
        font-size: 20px;
        font-weight: 500px;
        line-height: normal;
        padding: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>