<template>
    <div class="container-fluid">
        <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
            :is-full-page="true"></loading>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                trending: [],
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                },
            }
        },
        methods: {
            verifyUser: function () {
                this.isLoading = true;
                var data = {
                    id: this.$route.params.user_id,
                    key: this.$route.params.key
                }
                axios.post("/api/verify-web-user", data, this.config)
                    .then(result => {
                        localStorage.setItem('name', result.data.user.name);
                        localStorage.setItem('email', result.data.user.email);
                        
                        this.isLoading = false;
                        this.$swal(
                            "Email Verified",
                            'Now you can create your own recipe and give rating to a recipe.',
                            "success"
                        );
                        this.$router.push({
                            path: "/"
                        });

                        console.log(result);
                    }, error => {
                        this.$swal(
                            "Not Found",
                            'Unknown Entry.',
                            "error"
                        );
                        this.$router.push({
                            path: "/"
                        });
                        console.log(error);
                    });
            }
        },
        mounted: function () {
            this.verifyUser();
        }
    };
</script>
<style>

</style>