<template>
    <div class="container-fluid">
        <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
            :is-full-page="true"></loading>
        <my-list></my-list>
        <div class="wrapper-block">
            <button v-if="cartIngredients != undefined" class="btn cl-shp-btn" type="button"
                @click="clearShoppingList">Clear All</button>
            <base-alert v-if="noShopIng" type="default">
                <span class="alert-inner--text">
                    Your Shopping List is empty, add ingredients from recipes to buy them later
                </span>
            </base-alert>
            <ul class="sidebar-nav">
                <li v-for="ingredient in cartIngredients" :key="ingredient.id">
                    <div class="d-inline-block shop-ing" @click="addCartModal(ingredient.ingredient)"><i
                            class="fas fa-minus-square pr-2"></i> {{ingredient.ingredient}}</div>
                    <!-- Remove/Purchase from Shopping PopUp -->
                    <slide-y-up-transition mode="out-in" origin="center top">
                        <div id="recCart" v-if="cartModal" class="cart-modal">
                            <div class="body">
                                <span class="closeBtn" @click="cartModal=false"><i class="fa fa-times"></i></span>
                                <h2>Choose Action</h2>
                                <div class="actionBtns">
                                    <button @click="purchaseIngredient(cartSingleingredient)"
                                        class="btn btn-primary">Purchased</button>
                                    <button @click="removeIngredient(cartSingleingredient)"
                                        class="btn btn-primary">Remove</button>
                                </div>
                            </div>
                        </div>
                    </slide-y-up-transition>
                    <!-- Remove/Purchase from Shopping PopUp Ends -->
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import MyList from './MyList.vue';
    import {
        SlideYUpTransition
    } from 'vue2-transitions'
    export default {
        components: {
            MyList,
            SlideYUpTransition,
            Loading
        },
        data: () => {
            return {
                cartIngredients: [],
                cartSingleingredient: null,
                isLoading: false,
                noShopIng: false,
                cartModal: false,
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                }
            }
        },
        methods: {
            getCartIngredients: function () {
                this.isLoading = true;
                var data = {
                    device_id: localStorage.getItem('device_id')
                }
                axios.post('/api/cartIngredients', data, this.config)
                    .then(response => {
                        console.log(response.data.success)
                        this.cartIngredients = response.data.ingredients;
                        if (response.data.success == false) {
                            this.noShopIng = true;
                        }
                        this.isLoading = false;
                    }, error => {
                        console.log(error);
                        return error;
                    })
            },
            removeIngredient: function (name) {
                var data = {
                    device_id: localStorage.getItem('device_id'),
                    ingredient: name
                };
                this.isLoading = true;
                axios.post('/api/change', data, this.config)
                    .then(response => {
                        console.log(response);
                        this.cartModal = false;
                        this.$toast.open({
                            message: "Ingredient Removed",
                            type: 'success',
                        });
                        this.getCartIngredients();
                    }, error => {
                        console.log(error);
                        this.$toast.open({
                            message: "Something went wrong.",
                            type: 'error',
                        });
                    });
            },
            purchaseIngredient: function (name) {
                var data = {
                    device_id: localStorage.getItem('device_id'),
                    ingredient: name
                };
                this.isLoading = true;
                axios.post('/api/purchased', data, this.config)
                    .then(response => {
                        this.cartModal = false;
                        this.$toast.open({
                            message: response.data.msg,
                            type: 'success',
                        });
                        this.getCartIngredients();
                    }, error => {
                        console.log(error);
                        this.$toast.open({
                            message: "Something went wrong.",
                            type: 'error',
                        });
                    });
            },
            addCartModal: function (name) {
                this.cartSingleingredient = name;
                this.cartModal = true;
            },
            clearShoppingList: function () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "This will remove all ingredients from your shopping list!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ce3770',
                    cancelButtonColor: '#6d6d6d',
                    confirmButtonText: 'Yes, Remove all.'
                }).then((result) => {
                    if (result.isConfirmed) {
                        var data = {
                            device_id: localStorage.getItem('device_id'),
                        };
                        this.isLoading = true;
                        axios.post('/api/clear-cart-items', data, this.config)
                            .then(response => {
                                this.$toast.open({
                                    message: response.data.msg,
                                    type: 'success',
                                });
                                this.getCartIngredients();
                            }, error => {
                                console.log(error);
                                this.$toast.open({
                                    message: "Something went wrong.",
                                    type: 'error',
                                });
                            });
                    }
                })
            }
        },

        mounted() {
            this.getCartIngredients();
        }

    }
</script>
<style scoped>
    @media(min-width:768px) {
        .wrapper-block {
            width: 70%;
            margin: 0 auto;
        }
    }

    /* Sidebar Styles */

    .sidebar-nav {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .sidebar-nav li {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #cad1d7;
    }


    .sidebar-nav li a:active,
    .sidebar-nav li a:focus {
        text-decoration: none;
    }

    .cart-modal {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding-top: 100px;
        background-color: rgba(0, 0, 0, .1);
    }

    .cart-modal .body {
        width: 30%;
        border-radius: 10px;
        position: relative;
    }

    .cart-modal .body {
        background-color: #fff;
        padding: 20px;
    }

    .cart-modal .body h2 {
        text-align: center;
        margin: 0;
    }

    .cart-modal .body .form-group {
        margin-bottom: 10px;
    }

    .cart-modal .body label {
        margin-bottom: 2px;
    }

    .cart-modal .body .actionBtns {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .cart-modal .body .btn {
        background-color: #ce3770;
        color: #fff;
        width: 180px;
        height: 40px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: 0;
        margin-left: auto;
    }

    .closeBtn {
        position: absolute;
        right: -5px;
        top: -10px;
        border-radius: 50%;
        z-index: 999999;
        background-color: #fff;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .closeBtn i {
        color: #000;
        font-size: 20px;
    }

    .alert-default {
        background-color: #ce3770;
        border: 0;
    }

    .shop-ing {
        color: #ce3770;
        cursor: pointer;
    }

    .cl-shp-btn {
        background-color: #ce3770;
        color: #fff;
        width: 100px;
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-bottom: 20px;
    }
</style>