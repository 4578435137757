<template>
    <div class="container-fluid">
        <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
            :is-full-page="true"></loading>
        <div class="all-recipes">
            <div v-for="recipe in recipes.data" :key="recipe.id" class="recipe">
                <router-link :to="{ name: 'RecipesDetails', params: { recipe_id: recipe.id }}">
                    <img v-lazy="imgUrl+recipe.image_url" alt="" class="banner-img">
                    <h3 class="name">{{recipe.RecipieName}}</h3>
                </router-link>
            </div>
        </div>
        <pagination :data="recipes" :limit="8" @pagination-change-page="getAllRecipes"></pagination>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        components: {
            Loading,
        },
        data() {
            return {
                imgUrl: process.env.VUE_APP_IMG_URL,
                isLoading: false,
                recipes: {},
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                },
            }
        },
        methods: {
            getAllRecipes: function (page = 1) {
                this.loading = true;
                console.log(this.$route.params.id);
                axios.get('/api/all-web-recipes?page=' + page, this.config)
                    .then(response => {
                        this.loading = false;
                        this.recipes = response.data.recipes;
                    }, error => {
                        console.log(error);
                    });
            }
        },
        mounted: function () {
            this.getAllRecipes();
        }
    };
</script>
<style>
    .all-recipes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 30px 0;
        padding: 0 50px;
    }

    .all-recipes .recipe {
        width: 32%;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .2);
        margin-bottom: 30px;
        border-radius: 10px;
        overflow: hidden;
    }

    .all-recipes .recipe img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .all-recipes .recipe .name {
        color: #ce3770;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        padding: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .page-item.active .page-link {
        background-color: #ce3770 !important;
        color: #fff !important;
        border-color: #ce3770 !important;
    }

    .pagination {
        justify-content: center;
    }
    @media (max-width: 767.98px) {
        .all-recipes .recipe {
            width:100%!important;
        }
        .all-recipes{
            padding:0px!important;
        }
     }

</style>
