<template>
  <div class="containe-fluidr">
    <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
      :is-full-page="true"></loading>
    <my-list></my-list>
    <div class="sidebar-wrapper">
      <ul v-for="category in categories" :key="category.id" class="sidebar-nav">
        <li>
          <router-link
            :to="{ name: 'category-ingredients', params: { name: category.name, id: category.id }}">
            <div class="img-wrapper"><img :src="category.icon_url" :alt="category.name"></div>
            {{category.name}}<i class="fas fa-chevron-right float-right"></i>
          </router-link>
        </li>

      </ul>
    </div> <!-- /#sidebar-wrapper -->
  </div>

</template>
<script>
  import Loading from 'vue-loading-overlay';
  import axios from 'axios';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import MyList from './MyList.vue';
  export default {
    components: {
      MyList,
      Loading
    },
    data() {
      return {
        isLoading: false,
        categories: [],
        config: {
          headers: {
            Accept: 'application/json'
          }
        },
      }
    },
    methods: {
      getAllCategories: function () {
        this.isLoading = true;
        axios.get('/api/categories', this.config)
          .then(response => {
            this.isLoading = false;
            this.categories = response.data.categories;
          }, error => {
            console.log(error);
          })
      }
    },
    mounted: function () {
      this.getAllCategories();
    }

  }
</script>
<style scoped>
  /* Sidebar Styles */

  .sidebar-nav {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
  }


  .sidebar-nav li a:active,
  .sidebar-nav li a:focus {
    text-decoration: none;
  }


  @media(min-width:768px) {
    .sidebar-wrapper {
      width: 90%;
      margin: 0 auto;
    }

    #wrapper {
      padding-left: 0;
    }

    #wrapper.toggled {
      padding-right: 250px;
    }

    #page-content-wrapper {
      padding: 20px;
      position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
      position: relative;
      margin-right: 0;
    }
  }

  .img-wrapper {
    display: inline-block;
    height: 30px;
    width: 30px;
    position: relative;
  }

  .img-wrapper img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 10px;
    left: 0;
  }
</style>