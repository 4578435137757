<template ref="createdRecipes">
    <div class="container-fluid">
        <my-recipes></my-recipes>
        <div class="sidebar-wrapper">
            <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
                :is-full-page="true"></loading>
            <base-alert v-if="userLoggedIn == false" type="default">
                <span class="alert-inner--text">Please Login to see your recipes here.</span>
            </base-alert>
            <base-alert v-if="userLoggedIn == true && recipes.length < 1" type="default">
                <span class="alert-inner--text"><strong>No Recipes Found!</strong> Click on create recipe to add
                    one.</span>
            </base-alert>
            <div class="created-recipes">
                <div v-for="recipe in recipes" :key="recipe.id" class="recipe">
                    <span @click="delUserRecipe(recipe.id)" class="del_recipe_icon"><i class="fa fa-times"></i></span>
                    <div class="content">
                        <router-link :to="{ name: 'EditUserRecipe', params: { recipe_id: recipe.id }}">
                            <img v-lazy="recipe.image_url" alt="" class="banner-img">
                                <h3 class="name p-2">{{recipe.RecipieName}}</h3>
                        </router-link>
                    </div>
                </div>
            </div>
            <!-- <router-link :to="{ path: '/log-in/'}" class="create-recipe">
                <button type="submit" class="btn btn-primary my-5">Create Recipe</button>
            </router-link> -->
        </div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import MyRecipes from '../MyRecipes.vue';
    export default {
        name: "createdRecipes",
        components: {
            MyRecipes,
            Loading,
        },
        data() {
            return {
                isLoading: false,
                userLoggedIn: null,
                recipes: [],
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                },
            }
        },
        methods: {
            getAllCreatedRecipes: function () {
                if (localStorage.getItem('email') === 'undefined' || localStorage.getItem('email') ===
                    undefined) {
                    this.userLoggedIn = false;
                    this.recipes = [];
                    return;
                }
                this.userLoggedIn = true;
                this.loading = true;
                var data = {
                    device_id: localStorage.getItem('device_id')
                };
                axios.post('/api/user-recipes', data, this.config)
                    .then(response => {
                        this.loading = false;
                        console.log(response.data.recipes)
                        this.recipes = response.data.recipes;
                    }, error => {
                        console.log(error);
                    });
            },
            delUserRecipe: function (id) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "This will delete the recipe & you won't be able to revert this!",
                    icon: '',
                    showCancelButton: true,
                    confirmButtonColor: '#ce3770',
                    cancelButtonColor: '#6d6d6d',
                    confirmButtonText: 'Yes, Delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        var data = {
                            recipe_id: id,
                            device_id: localStorage.getItem('device_id')
                        };
                        this.isLoading = true;
                        axios.post('/api/delete-user-recipe', data, this.config)
                            .then(response => {
                                console.log(response);
                                this.isLoading = false;
                                this.$toast.open({
                                    message: "Recipe deleted.",
                                    type: 'success',
                                });
                                this.getAllCreatedRecipes();
                            }, error => {
                                console.log(error);
                                this.$toast.open({
                                    message: "Something went wrong.",
                                    type: 'error',
                                });
                            });
                    }
                })
            }
        },
        mounted: function () {
            this.getAllCreatedRecipes();
        }
    }
</script>
<style scoped>
    @media(min-width:768px) {
        .sidebar-wrapper {
            width: 99%;
            margin: 0 auto;
            position: relative;
        }
    }

    .create-recipe {
        position: absolute;
        right: 0px;
    }

    .btn-primary {
        color: #fff;
        background-color: #ce3770;
        border-color: #ce3770;
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
        border-radius: 25px;
    }

    .btn-primary:hover {
        color: #fff;
        background-color: #ce3770;
        border-color: #ce3770;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #ce3770;
        border-color: #ce3770;
    }

    .created-recipes {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin: 30px 0;
        padding: 0 50px;
    }

    .created-recipes .recipe {
        display: block;
        width: 30%;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .2);
        margin-bottom: 30px;
        border-radius: 10px;
        margin-right: 20px;
        position: relative;
    }

    .created-recipes .recipe img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        margin-bottom: 10px;
        display: inline-block;
    }

    .wrapper-details {
        display: inline-block;
        color: #000000;
    }

    .created-recipes .recipe .content{
        overflow: hidden;
    }

    .created-recipes .recipe .name {
        color: #ce3770;
        font-size: 20px;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

    }

    .alert-default {
        background-color: #ce3770;
        border: 0;
    }

    .del_recipe_icon {
        position: absolute;
        background-color: #fff;
        color: tomato;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        top: -5px;
        right: -5px;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, .2);
        cursor: pointer;
    }

    .del_recipe_icon i {
        font-size: 14px;
    }
</style>