<template>
    <div class="container-fluid">
        <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
            :is-full-page="true"></loading>
        <div class="wrapper-block header-block my-3">
            <router-link class="nav-link d-inline-block back-list" to="/ingredients-list-category" eaxct><i
                    class="fas fa-chevron-left"></i> MY LIST</router-link>
            <h2 class="d-inline-block">{{catName}} AT HOME </h2>
            <router-link :to="{ path: '/show-ingredient-recipes'}">
                <button type="submit" class="btn btn-primary">Show Recipes</button>
            </router-link>
        </div>
        <div class="sidebar-wrapper">
            <div class="searchBar my-4">
                <input v-model="searchIngredients" type="text" class="form-control"
                    placeholder="Search an ingredient..." />
            </div>
            <div v-if="homeIngredients.length" class="selected-ingredients">
                <label v-for="(ingredient, index) in homeIngredients" :key="index" class="selected-ingredient">
                    {{ingredient.name}}
                </label>
            </div>

            <ul class="sidebar-nav">
                <li class="ingredient-check" v-for="(ingredient, index) in resultQuery" :key="index">
                    <input type="checkbox" :id="'ing-'+ingredient.id" @click="addIngToCart(ingredient.name)"
                        :value="ingredient.name" :checked="ingredient.in_home">
                    <label :for="'ing-'+ingredient.id">{{ingredient.name}}</label>
                </li>
                <base-alert v-if="resultQuery == ''" type="default">
                    <span class="alert-inner--text"><strong>Oops!</strong> No ingredient matches your search query.</span>
                </base-alert>
            </ul>
        </div> <!-- /#sidebar-wrapper -->
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: "IngredientsList",
        components: {
            Loading
        },
        data: () => {
            return {
                catName: null,
                searchIngredients: null,
                ingredients: {},
                isLoading: false,
                homeIngredients: [],
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                }
            }
        },

        methods: {
            getCategoryIngredients: function () {
                var data = {
                    device_id: localStorage.getItem('device_id'),
                    cat_id: this.$route.params.id
                };
                this.isLoading = true;
                axios.post('/api/catIngredients', data, this.config)
                    .then(response => {
                        this.isLoading = false;
                        this.ingredients = response.data.ingredients;
                    }, error => {
                        return error;
                    })
            },
            addIngToCart: function (name) {
                var data = {
                    ingredient: name,
                    device_id: localStorage.getItem('device_id'),
                };
                this.isLoading = true;
                axios.post('/api/homeIngredient', data, this.config)
                    .then(response => {
                        console.log(response);
                        this.getCategoryIngredients();
                    }, error => {
                        console.log(error);
                        this.$toast.open({
                            message: "Something went wrong.",
                            type: 'error',
                        });
                    });
            }
        },
        mounted() {
            this.catName = this.$route.params.name;
            this.getCategoryIngredients();
        },
        watch: {
            ingredients() {
                this.homeIngredients = [];
                if (this.ingredients.length) {
                    this.ingredients.filter((item) => {
                        if (item.in_home == true) {
                            this.homeIngredients.push(item)
                        }
                    })
                }
            }
        },
        computed: {
            resultQuery() {
                if (this.searchIngredients) {
                    return this.ingredients.filter((item) => {
                        return this.searchIngredients.toLowerCase().split(' ').every(v => item.name
                            .toLowerCase()
                            .includes(v))
                    })
                } else {
                    return this.ingredients;
                }
            }
        }

    }
</script>
<style scoped>
    /* Sidebar Styles */

    .sidebar-nav {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .sidebar-nav .ingredient-check {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
    }

    .sidebar-nav .ingredient-check:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 10px;
    }

    .sidebar-nav li a:active,
    .sidebar-nav li a:focus {
        text-decoration: none;
    }


    @media(min-width:768px) {
        .wrapper-block {
            width: 90%;
            margin: 0 auto;
            position: relative
        }

        h2 {
            color: #ce3770 !important;
        }

        .sidebar-wrapper {
            width: 90%;
            margin: 0 auto;
        }

        #wrapper {
            padding-left: 0;
        }

        #wrapper.toggled {
            padding-right: 250px;
        }

        #page-content-wrapper {
            padding: 20px;
            position: relative;
        }

        #wrapper.toggled #page-content-wrapper {
            position: relative;
            margin-right: 0;
        }
    }

    .header-block {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .img-wrapper {
        display: inline-block;
        height: 30px;
        width: 30px;
        position: relative;
    }

    .img-wrapper img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 10px;
        left: 0;
    }

    .ingredient-check input[type=checkbox] {
        position: relative;
        width: 1em;
        height: 1em;
        color: #363839;
        border: 1px solid #bdc1c6;
        border-radius: 2px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    }

    .ingredient-check input[type=checkbox]::before {
        position: absolute;
        content: "";
        display: block;
        top: 1px;
        left: 4px;
        width: 6px;
        height: 10px;
        border-style: solid;
        border-color: #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
    }

    .ingredient-check input[type=checkbox]:checked {
        color: #fff;
        border-color: #ce3770;
        background: #ce3770;
    }

    .ingredient-check input[type=checkbox]:checked::before {
        opacity: 1;
    }

    .ingredient-check input[type=checkbox]:checked~label::before {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .ingredient-check label {
        position: relative;
        cursor: pointer;
        font-size: 1em;
        font-weight: 400;
        padding-left: 5px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-bottom: 0px;
    }

    .ingredient-check label::before {
        position: absolute;
        content: attr(data-content);
        color: #9c9e9f;
        -webkit-clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
        clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
        text-decoration: line-through;
        text-decoration-thickness: 3px;
        -webkit-text-decoration-color: #363839;
        text-decoration-color: #363839;
        transition: -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .btn-primary {
        color: #fff;
        background-color: #ce3770;
        border-color: #ce3770;
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
        border-radius: 25px;
    }

    .btn-primary:hover {
        color: #fff;
        background-color: #ce3770;
        border-color: #ce3770;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #ce3770;
        border-color: #ce3770;
    }

    .selected-ingredient {
        display: inline-block;
        padding: 10px;
        background: gray;
        color: white;
        border-left: 4px solid #ce3770;
        border-radius: 0 15px 15px 0;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .alert-default {
        background-color: #ce3770;
        border: 0;
    }
</style>