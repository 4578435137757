<template>
    <div class="container-fluid">
        <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
            :is-full-page="true"></loading>
        <div class="form-group">
            <input type="text" v-validate="'required|min:3'" name="search" v-on:keyup="searchRecipes"
                v-on:keyup.enter="searchRecipes" v-model="searchQuery" class="form-control mt-3"
                placeholder="Search for salad,pasta..." />
            <span v-show="errors.has('search')" class="help is-danger">{{ errors.first('search') }}</span>
        </div>
        <div v-if="!userSearched" class="explore-recipes">
            <div class="featured-block mb-5">
                <h2 class="my-2"><a href="#" alt="FEATURED" class="text-black">FEATURED</a></h2>
                <carousel :per-page="1">
                    <slide v-for="recipe in featured" :key="recipe.id" class="block-style-banner">
                        <router-link :to="{ name: 'RecipesDetails', params: { recipe_id: recipe.id }}">
                            <div class="banner-block">
                                <img :src="recipe.image_url" alt="" class="banner-img">
                                <div class="desc-block">
                                    <div class="p-3">
                                        <h2 class="h1">{{recipe.RecipieName}}</h2>
                                        <span class="pr-2">17 Ingredients</span>
                                        <span v-if="recipe.time_estimate"><i class="far fa-clock"></i>
                                            {{recipe.time_estimate - recipe.time_unit}}</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </slide>
                </carousel>
            </div>
            <div class="trending-block mb-5">
                <router-link class="recipe-nav-link" to="/trending-recipes">TRENDING <i
                        class="fas fa-chevron-right"></i></router-link>
                <carousel :per-page="1" :spacePaddingMaxOffsetFactor="50" :perPageCustom="[[576, 2],[768, 3], [1024, 5]]">
                    <slide v-for="recipe in trending" :key="recipe.id" class="slide p-3">
                        <router-link :to="{ name: 'RecipesDetails', params: { recipe_id: recipe.id }}">
                            <div class="block">
                                <img v-lazy="recipe.image_url" alt="" class="banner-img">
                            </div>
                            <h3 class="name">{{recipe.RecipieName}}</h3>
                            <span v-if="recipe.time_estimate"><i class="far fa-clock"></i>
                                {{recipe.time_estimate - recipe.time_unit}}</span>
                        </router-link>
                    </slide>
                </carousel>
            </div>
            <div class="popular-block">
                <router-link class="recipe-nav-link" to="/popular-recipes">POPULAR <i class="fas fa-chevron-right"></i>
                </router-link>
                <carousel :per-page="1" :spacePaddingMaxOffsetFactor="50" :perPageCustom="[[576, 2],[768, 3], [1024, 5]]">
                    <slide v-for="recipe in popular" :key="recipe.id" class="slide p-3">
                        <router-link :to="{ name: 'RecipesDetails', params: { recipe_id: recipe.id }}">
                            <div class="block">
                                <img v-lazy="recipe.image_url" alt="" class="banner-img">
                            </div>
                            <h3 class="name">{{recipe.RecipieName}}</h3>
                            <span v-if="recipe.time_estimate"><i class="far fa-clock"></i>
                                {{recipe.time_estimate - recipe.time_unit}}</span>
                        </router-link>
                    </slide>
                </carousel>
            </div>
        </div>
        <div v-if="userSearched && searchResult.length" class="search-recipes">
            <div v-for="recipe in searchResult" :key="recipe.id" class="recipe">
                <router-link :to="{ name: 'RecipesDetails', params: { recipe_id: recipe.id }}">
                    <img v-lazy="recipe.image_url" alt="" class="banner-img">
                    <h3 class="name">{{recipe.RecipieName}}</h3>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {
        Carousel,
        Slide
    } from 'vue-carousel';

    export default {
        components: {
            Carousel,
            Slide,
            Loading
        },
        data() {
            return {
                isLoading: false,
                userSearched: false,
                searchQuery: '',
                searchResult: [],
                trending: [],
                featured: [],
                popular: [],
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                },
            }
        },
        methods: {
            getExploreRecipes: function () {
                this.isLoading = true;
                axios.get('/api/explore-recipes', this.config)
                    .then(response => {
                        this.isLoading = false;
                        this.trending = response.data.trending;
                        this.featured = response.data.featured;
                        this.popular = response.data.popular;
                    }, error => {
                        console.log(error);
                    });
            },
            searchRecipes: function (e) {
                if (this.searchQuery === '') {
                    this.$nextTick(() => {
                        this.errors.clear();
                        this.$nextTick(() => {
                            this.$validator.reset();
                        });
                    });
                    this.searchResult = [];
                    this.userSearched = false;
                }
                if (e.keyCode === 13) {
                    this.$validator.validate().then((result) => {
                        if (result) {
                            this.isLoading = true;
                            var data = {
                                search: this.searchQuery,
                                device_id: localStorage.getItem('device_id')
                            };
                            axios.post('/api/search-web-recipes', data, this.config)
                                .then(response => {
                                    if (response.data.success === true) {
                                        this.userSearched = true;
                                        this.isLoading = false;
                                        this.searchResult = response.data.recipies;
                                    } else {
                                        this.isLoading = false;
                                        this.$swal(
                                            "Nothing",
                                            'No recipe found mathching this query.',
                                            "error"
                                        );
                                    }
                                }, error => {
                                    console.log(error);
                                });
                        }
                    }).catch(() => {});
                }
            }
        },
        mounted: function () {
            this.getExploreRecipes();
        }
    };
</script>
<style>
    .block-style i,
    .desc-block i {
        color: #ce3770
    }

    .text-black {
        color: #000000;
    }

    a:hover {
        color: #ce3770;
    }

    .block-style-banner {
        text-align: center;
    }

    .desc-block {
        position: absolute;
        bottom: 0px;
        width: 100%;
        color: #ffffff;
        text-align: left;
        box-shadow: inset 0 -103px 87px -15px #5a394d;
        border-radius: 0 0 25px 25px;
    }

    .h1 {
        color: #ffffff;
    }

    .recipe-nav-link {
        font-size: 1.5rem;
        color: #ce3770;
        padding-left: 10px;
        font-weight: 600;
    }

    .banner-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .explore-recipes {
        margin-top: 30px;
    }

    .featured-block {
        width: 100%;
    }

    .featured-block .banner-block {
        position: relative;
        border-radius: 25px;
        overflow: hidden;
        height: 500px;
        overflow: hidden;
    }

    .trending-block,
    .popular-block {
        width: 100%;
    }

    .trending-block .slide,
    .popular-block .slide {
        height: auto;
        width: 18%;
    }

    .trending-block .slide .block img,
    .popular-block .slide .block img {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
    }

    .trending-block .slide .name,
    .popular-block .slide .name {
        font-size: 16px;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .VueCarousel-dot {
        border: 1px solid #000000 !important;
        padding: 0px !important;
        margin: 5px !important;
    }

    .btn-primary {
        background-color: #ce3770;
        border-color: #ce3770
    }

    /* Search */
    .search-button {
        position: absolute;
        right: 17px;
        background: #ce3770;
        cursor: pointer;
        width: 60px;
        color: white;
        padding-top: 9px;
        padding-bottom: 9px;
        border-radius: 0 0.375rem 0.375rem 0;
        border: none;
    }

    .search-button:focus {
        outline: 0px;
    }

    .search-recipes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 30px 0;
        padding: 0 50px;
    }

    .search-recipes .recipe {
        width: 48%;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .2);
        margin-bottom: 30px;
        border-radius: 10px;
        overflow: hidden;
    }

    .search-recipes .recipe img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .search-recipes .recipe .name {
        color: #ce3770;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        padding: 20px;
    }
</style>
