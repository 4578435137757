<template>
    <div class="container-fluid">
        <my-recipes></my-recipes>
        <div class="sidebar-wrapper">
            <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
                :is-full-page="true"></loading>
            <base-alert v-if="noFavs" type="default">
                <span class="alert-inner--text"><strong>No Favorite Recipes!</strong> Please add favorite recipes, and
                    it will appear here.</span>
            </base-alert>
            <div v-if="recipes.length" class="favorite-recipes">
                <div v-for="recipe in recipes" :key="recipe.id" class="recipe">
                    <span class="clIcon" @click="deleteFav(recipe.id)"><i class="fa fa-times"></i></span>
                    <router-link :to="{ name: 'RecipesDetails', params: { recipe_id: recipe.id }}">
                        <div class="content">
                            <img :src="recipe.image_url" />
                            <h3 class="name">{{recipe.RecipieName}}</h3>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import MyRecipes from '../MyRecipes.vue';
    export default {
        name: "FavoriteRecipes",
        components: {
            MyRecipes,
            Loading,
        },
        data: () => {
            return {
                recipes: {},
                noFavs: false,
                isLoading: false,
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                },
            }
        },
        methods: {
            getFavoriteRecipes: function () {
                this.recipes = {};
                var data = {
                    device_id: localStorage.getItem('device_id')
                };
                this.isLoading = true;
                axios.post('/api/getfavorites', data, this.config)
                    .then(response => {
                        this.recipes = response.data.favorites;
                        this.isLoading = false;
                    }, error => {
                        this.isLoading = false;
                        this.noFavs = true;
                        return error;
                    });
            },
            deleteFav: function (id) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: '',
                    showCancelButton: true,
                    confirmButtonColor: '#ce3770',
                    cancelButtonColor: '#6d6d6d',
                    confirmButtonText: 'Yes, remove it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        var data = {
                            recipie_id: id,
                            device_id: localStorage.getItem('device_id')
                        };
                        this.isLoading = true;
                        axios.post('/api/delfavorite', data, this.config)
                            .then(response => {
                                console.log(response);
                                this.isLoading = false;
                                this.$toast.open({
                                    message: "Recipe removed from your favorites.",
                                    type: 'success',
                                });
                                this.getFavoriteRecipes();
                            }, error => {
                                console.log(error);
                                this.$toast.open({
                                    message: "Something went wrong.",
                                    type: 'error',
                                });
                            });
                    }
                })
            }
        },
        mounted() {
            this.getFavoriteRecipes();
        }
    }
</script>

<style scoped>
    @media(min-width:768px) {
        .sidebar-wrapper {
            width: 99%;
            margin: 0 auto;
        }
    }

    .favorite-recipes {
        padding: 30px 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }

    .favorite-recipes .recipe {
        width: 23%;
        margin-bottom: 20px;
        margin-right: 20px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .4);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
    }

    .favorite-recipes .recipe .content {
        position: relative;
    }

    .favorite-recipes .recipe .content img {
        height: 250px;
        width: 100%;
        object-fit: cover;
    }

    .favorite-recipes .recipe .content h3 {
        color: #ce3770;
        font-size: 20px;
        font-weight: 500px;
        line-height: normal;
        margin: 20px 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .favorite-recipes .recipe .clIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: rgba(255, 255, 255, .6);
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        z-index: 999;
        cursor: pointer;
    }

    .favorite-recipes .recipe .clIcon i {
        font-size: 16px;
        color: #444;
    }

    .alert-default {
        background-color: #ce3770;
        border: 0;
    }
</style>