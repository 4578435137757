<template>
    <div>
        <div class="container-fluid py-3">
            <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
                :is-full-page="true"></loading>
            <base-alert v-if="noRevs" type="default">
                <span class="alert-inner--text"><strong>No Reviews Found!</strong> Open a recipe, leave a
                    review & it will appear here.</span>
            </base-alert>
            <div v-if="reviews.length" class="my-reviews">
                <div v-for="review in reviews" :key="review.id" class="review">
                    <div class="actionIcons">
                        <span @click="editReview(review)"><i class="fas fa-edit"></i></span>
                        <span @click="deleteReview(review)"><i class="fas fa-trash-alt"></i></span>
                    </div>
                    <div class="content">
                        <star-rating :star-size="20" :border-width="3" border-color="#ce3770" inactive-color="#fff"
                            active-color="#ce3770" :show-rating="false" :rating="parseFloat(review.rating)"
                            :read-only="true" :increment=".5"></star-rating>
                        <h3>Recipe Name: <router-link
                                :to="{ name: 'RecipesDetails', params: { recipe_id: review.recipe.id }}">
                                {{review.recipe.RecipieName}}
                            </router-link>
                        </h3>
                        <p class="text">{{review.review}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Edit Reviews PopUp -->
        <slide-y-up-transition mode="out-in" origin="center top">
            <div id="recReview" v-if="editReviewModal" class="review-modal">
                <div class="body">
                    <h2>Edit Review</h2>
                    <span class="closeBtn" @click="editReviewModal=false"><i class="fa fa-times"></i></span>
                    <star-rating :star-size="24" :border-width="2" border-color="#ce3770" inactive-color="#fff"
                        active-color="#ce3770" :show-rating="false" :increment="1" v-model="editedReview.rating">
                    </star-rating>
                    <div class="form-group">
                        <label>Review:</label>
                        <input type="text" v-model="editedReview.review" v-validate="'required:min:3'" name="review"
                            class="form-control" />
                        <span v-show="errors.has('review')" class="help is-danger">{{ errors.first('review') }}</span>
                    </div>
                    <div class="form-group">
                        <label>Private:</label>
                        <select class="form-control" v-model="editedReview.private" v-validate="'required'"
                            name="private">
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                    <div class="form-group text-right">
                        <button class="btn" @click="updateReview()" type="button">Update</button>
                    </div>
                </div>
            </div>
        </slide-y-up-transition>
        <!-- Edit Reviews PopUp Ends -->
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import StarRating from 'vue-star-rating';
    import {
        SlideYUpTransition
    } from 'vue2-transitions'
    export default {
        name: "FavoriteRecipes",
        components: {
            Loading,
            StarRating,
            SlideYUpTransition
        },
        data: () => {
            return {
                reviews: {},
                editedReview: {},
                editReviewModal: false,
                noRevs: false,
                isLoading: false,
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                },
            }
        },
        methods: {
            getUserReviews: function () {
                this.reviews = {};
                var data = {
                    device_id: localStorage.getItem('device_id')
                };
                this.isLoading = true;
                axios.post('/api/get-user-reviews', data, this.config)
                    .then(response => {
                        this.reviews = response.data.reviews;
                        this.isLoading = false;
                    }, error => {
                        this.isLoading = false;
                        this.noRevs = true;
                        return error;
                    });
            },
            editReview: function (review) {
                this.editedReview = {};
                this.editedReview = review;
                this.editReviewModal = true;
            },
            updateReview: function () {
                this.$validator.validate().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        this.editedReview.review_id = this.editedReview.id;
                        axios.post('/api/update-review', this.editedReview, this.config)
                            .then(response => {
                                console.log(response);
                                this.getUserReviews();
                                this.isLoading = false;
                                this.editedReview = {}
                                this.editReviewModal = false;
                                this.$toast.open({
                                    message: "Review updated Successfully.",
                                    type: 'success',
                                });
                            }, error => {
                                console.log(error);
                                this.$toast.open({
                                    message: "Something went wrong.",
                                    type: 'error',
                                });
                            });
                    }
                }).catch(() => {});
            },
            deleteReview: function (review) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "This will delete the review!",
                    icon: '',
                    showCancelButton: true,
                    confirmButtonColor: '#ce3770',
                    cancelButtonColor: '#6d6d6d',
                    confirmButtonText: 'Yes, Delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        var data = {
                            review_id: review.id,
                            device_id: localStorage.getItem('device_id')
                        };
                        this.isLoading = true;
                        axios.post('/api/delete-review', data, this.config)
                            .then(response => {
                                console.log(response);
                                this.isLoading = false;
                                this.$toast.open({
                                    message: "Review deleted successfuly.",
                                    type: 'success',
                                });
                                this.getUserReviews();
                            }, error => {
                                console.log(error);
                                this.$toast.open({
                                    message: "Something went wrong.",
                                    type: 'error',
                                });
                            });
                    }
                })
            }
        },
        mounted() {
            this.getUserReviews();
        }
    }
</script>

<style scoped>
    .my-reviews {
        padding: 30px 60px;
    }

    .my-reviews .review {
        margin-bottom: 20px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .4);
        padding: 0;
        display: flex;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
    }

    .my-reviews .review img {
        height: 250px;
        width: 250px;
        object-fit: cover;
    }

    .my-reviews .review .actionIcons {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
    }

    .my-reviews .review .actionIcons span {
        background-color: #ce3770;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        z-index: 999;
        cursor: pointer;
    }

    .my-reviews .review .actionIcons span:first-child {
        margin-right: 10px;
    }

    .my-reviews .review .actionIcons span i {
        font-size: 22px;
        color: #fff;
    }

    .my-reviews .review .content {
        padding: 20px 10px;
    }

    .my-reviews .review .content .vue-star-rating {
        margin-bottom: 10px;
    }

    .my-reviews .review .content h3 {
        color: #444;
        font-size: 26px;
        line-height: normal;
        margin-bottom: 10px;
    }

    .my-reviews .review .content .name {
        font-size: 22px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 10px;
        color: #444;
    }

    .my-reviews .review .content .text {
        font-size: 18px;
        line-height: normal;
        margin-bottom: 0px;
        color: #444;
    }

    .alert-default {
        background-color: #ce3770;
        border: 0;
    }

    .review-modal {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding-top: 100px;
        background-color: rgba(0, 0, 0, .5);
    }

    .review-modal .body {
        width: 60%;
        border-radius: 10px;
        position: relative;
    }

    .review-modal .body {
        background-color: #fff;
        padding: 20px;
    }

    .review-modal .body h2 {
        text-align: center;
        margin: 0;
    }

    .review-modal .body .form-group {
        margin-bottom: 10px;
    }

    .review-modal .body label {
        margin-bottom: 2px;
    }

    .review-modal .body .vue-star-rating {
        margin-bottom: 20px;
    }

    .review-modal .body .btn {
        background-color: #ce3770;
        color: #fff;
        width: 200px;
        height: 40px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: 0;
        margin-left: auto;
    }

    .closeBtn {
        position: absolute;
        right: -5px;
        top: -10px;
        border-radius: 50%;
        z-index: 999999;
        background-color: #fff;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .closeBtn i {
        color: #000;
        font-size: 20px;
    }
</style>