<template>
    <div class="container my-5">
        <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
            :is-full-page="true"></loading>
        <form>
            <div class="form-group">
                <label for="recipe_name">Recipe Name</label>
                <input v-model="recipe.RecipieName" type="text" class="form-control" id="recipe_name" name="name"
                    placeholder="Recipe Name" v-validate="'required|min:5'">
                <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>
            <div class="form-group">
                <label for="photo">Photo</label>
                <img v-show="!newImg" class="rec_img" :src="recipe.image_url" />
                <picture-input v-if="newImg" ref="pictureInput" :hideChangeButton="true" @change="onChange" width="1300"
                    height="300" margin="16" accept="image/jpeg,image/png" size="30" buttonClass="btn" :customStrings="{
                        upload: '<h1>Bummer!</h1>',
                        drag: 'Change Photo. Max Size: 500kb'
                    }" v-model="recipe.recipe_image">
                </picture-input>
                <div class="img_action_buttons">
                    <button type="button" v-if="!newImg" @click="changeImage" class="btn change_img_btn btn-primary">Change
                        Image</button>
                    <button type="button" v-if="newImg" @click="cancelNewImage"
                        class="btn cancel_img_btn btn-seconday">Cancel</button>
                </div>
                <span v-show="imageError" class="help is-danger">The recipe image is required.</span>
            </div>

            <div class="form-group time_estimation_block">
                <label for="time_estimate">Time Estimate(Optional)</label>
                <input v-model="recipe.time_estimate" type="number" min="1" class="form-control" id="time_estimate"
                    placeholder="Time Estimate">

                <div class="box">
                    <label>
                        <input type="radio" name="time_unit" value="hours" v-model="recipe.time_unit">
                        <span class="hours">Hours</span>
                    </label>
                    <label>
                        <input type="radio" name="time_unit" value="minutes" v-model="recipe.time_unit">
                        <span class="minutes">Minutes</span>
                    </label>
                </div>
            </div>
            <div class="form-group">
                <label for="nr_servings">Number of Serings(Optional) :</label>
                <input v-model="recipe.number_of_servings" type="number" min="1" class="form-control" id="nr_servings"
                    placeholder="Number of Servings">
            </div>

            <div class="form-group">
                <label for="ingredients">Ingredients :</label>
                <div v-for="(ing, index) in recipe.Ingredients" :key="index" class="input field d-flex">
                    <input v-model="recipe.Ingredients[index]" :key="index" type="text" name="ingredient"
                        class="form-control mb-2" placeholder="Your Ingredient" v-validate="'required'" />
                    <span class="delFieldIcon" v-if="index > 1 " @click="removeIngField(index)"><i
                            class="fa fa-trash"></i></span>
                </div>
                <span v-show="errors.has('ingredient')" class="help is-danger">{{ errors.first('ingredient') }}</span>
                <a @click="addIngField()" class="m-2 cursor-pointer"><i class="fas fa-plus"></i>
                    Add another ingredient</a>
            </div>

            <div class="form-group">
                <label for="method">Method :</label>
                <div v-for="(method, index) in recipe.Method" :key="index" class="input field d-flex">
                    <input v-model="recipe.Method[index]" :key="index" name="method" type="text"
                        class="form-control mb-2" placeholder="Enter Method" v-validate="'required'" />
                    <span class="delFieldIcon" v-if="index >= 1" @click="removeMethodField(index)"><i
                            class="fa fa-trash"></i></span>
                </div>
                <span v-show="errors.has('method')" class="help is-danger">{{ errors.first('method') }}</span>
                <a @click="addMethodField()" class="m-2 cursor-pointer"><i class="fas fa-plus"></i> Add
                    another step </a>
            </div>
            <div class="form-group">
                <label for="video">Video Link (Optional): </label>
                <input v-model="recipe.vid" type="text" name="video_url" v-validate="'url'"
                    class="form-control" id="video" placeholder="Video Link">
                <span v-show="errors.has('video_url')" class="help is-danger">{{ errors.first('video_url') }}</span>
            </div>
            <label for="video">Make My Recipe: </label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="private_public" id="public" value="0"
                        v-model="recipe.private" checked="checked">
                    <label class="form-check-label" for="public">Public</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="private_public" id="private" value="1"
                        v-model="recipe.private">
                    <label class="form-check-label" for="private">Private</label>
                </div>
            </div>
            <button type="button" @click="updateUserRecipe" class="btn btn-primary float-right mb-5">Update
                Recipe</button>
        </form>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import axios from 'axios';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import PictureInput from 'vue-picture-input';
    //    import { required } from "vuelidate/lib/validators";
    export default {
        components: {
            PictureInput,
            Loading
        },
        data() {
            return {
                recipe: {},
                newImg: false,
                imageError: false,
                isLoading: false,
                isSubmitted: false,
                prefillOptions: {
                    mediaType: 'image/png,image/jpeg,image/jpg'
                },
                config: {
                    headers: {
                        Accept: 'application/json'
                    }
                },

            }
        },
        methods: {
            changeImage: function () {
                this.newImg = true;
            },
            cancelNewImage: function(){
                this.newImg = false;
                this.recipe.recipe_image = undefined;
            },
            getUserRecipe: function () {
                this.isLoading = true;
                var data = {
                    recipe_id: this.$route.params.recipe_id,
                    device_id: localStorage.getItem('device_id')
                }
                axios.post('/api/get-web-recipe', data, this.config)
                    .then(response => {
                        if (response.data.recipie.created_by == localStorage.getItem('device_id')) {
                            this.recipe = response.data.recipie;
                            for (var key in this.recipe) {
                                if (this.recipe[key] == 'null') {
                                    this.recipe[key] = null;
                                }
                            }
                            this.isLoading = false;
                        } else {
                            this.$swal(
                                "Not Found",
                                'Unknown Entry.',
                                "error"
                            );
                            this.$router.push({
                                path: "/"
                            });
                        }
                    }, error => {
                        console.log(error);
                        this.$swal(
                            "Not Found",
                            'Unknown Entry.',
                            "error"
                        );
                        this.$router.push({
                            path: "/"
                        });
                    });
            },
            addIngField() {
                this.recipe.Ingredients.push('');
            },
            removeIngField(index) {
                this.recipe.Ingredients.splice(index, 1);
            },
            addMethodField() {
                this.recipe.Method.push('');
            },
            removeMethodField(index) {
                this.recipe.Method.splice(index, 1);
            },
            onChange() {
                this.imageError = false;
                this.recipe.recipe_image = this.$refs.pictureInput.file;
                console.log(this.recipe.recipe_image);
            },
            updateUserRecipe: function () {
                this.$validator.validate().then((result) => {
                    if (result) {
                        var recipe_data = JSON.parse(JSON.stringify(this.recipe));
                        recipe_data.recipe_image = this.recipe.recipe_image;
                        recipe_data.device_id = localStorage.getItem('device_id');
                        recipe_data.recipe_id = this.recipe.id;
                        recipe_data.Ingredients = recipe_data.Ingredients.join(',');
                        recipe_data.Method.forEach((method, index) => {
                            if (method.match(/\.$/)) {
                                recipe_data.Method[index] = method;
                            } else {
                                recipe_data.Method[index] = method + '.';
                            }
                        });
                        recipe_data.Method = recipe_data.Method.join(' ');
                        var formdata = this.toFormData(recipe_data);
                        axios.post('/api/update-web-user-recipe', formdata, this.config)
                            .then(response => {
                                console.log(response);
                                this.$swal(
                                    "Hurray",
                                    'Recipe has been updated successfully.',
                                    "success"
                                );
                                this.$router.push({
                                    path: "/created-recipes"
                                });
                            }, error => {
                                this.$toast.open({
                                    message: error.response.data.message === '' ? 'Something went wrong' : error.response.data.message,
                                    type: 'error',
                                });
                                return error;
                            });
                    }
                }).catch(() => {});
            },
            toFormData: function (obj) {
                var form_data = new FormData();
                for (var key in obj) {
                    form_data.append(key, obj[key]);
                }
                return form_data;
            },
        },
        mounted() {
            this.getUserRecipe();
        }
    };
</script>
<style>
    .preview-container {
        border-radius: 25px !important;
        border: 1px solid #f8f8f8 !important;
        -webkit-box-shadow: 0px 0px 8px -5px #ce3770 !important;
        box-shadow: 0px 0px 8px -5px #ce3770 !important;
    }

    .time_estimation_block {
        position: relative !important;
    }

    /* Time Unit */
    .box {
        position: absolute;
        top: 42px;
        right: 35px;
    }

    .box label {
        position: relative;
        cursor: pointer;
    }

    .box label input {
        visibility: hidden;
    }

    .box label span {
        position: relative;
        display: inline-block;
        padding: 0 10px;
        color: #444;
        border-radius: 4px;
    }

    .box label input:checked~span {
        color: #fff;
        background: #ce3770;
    }

    /* Radio Button */
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked+label,
    [type="radio"]:not(:checked)+label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    [type="radio"]:checked+label:before,
    [type="radio"]:not(:checked)+label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }

    [type="radio"]:checked+label:after,
    [type="radio"]:not(:checked)+label:after {
        content: '';
        width: 18px;
        height: 18px;
        background: #ce3770;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    .btn-primary {
        color: #fff;
        background-color: #ce3770;
        border-color: #ce3770;
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    }

    .btn-primary:hover {
        color: #fff;
        background-color: #ce3770;
        border-color: #ce3770;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #ce3770;
        border-color: #ce3770;
    }

    .form-control {
        border: none;
        box-shadow: 0px 0px 8px -5px #CE3770;
    }

    .cursor-pointer {
        cursor: pointer
    }

    .delFieldIcon {
        position: absolute;
        right: 5px;
        top: 10px;
        cursor: pointer;
    }

    .delFieldIcon i {
        color: tomato;
    }

    .input.field {
        position: relative;
    }

    .rec_img {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
    }

    .img_action_buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .img_action_buttons .btn{
        width: 200px;
    }

    .img_action_buttons .btn:first-child{
        margin-right: 20px;
    }

    .cancel_img_btn, .cancel_img_btn:hover, .cancel_img_btn:focus {
        background-color: #525f7f;
        color: #fff;
    }
    .preview-container{
        max-width: 100% !important;
    }
</style>
